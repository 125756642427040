import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'

import './style.scss'

interface ContentLoaderProps {
  hasItems: boolean;
  retrieved: boolean;
  notFoundHeaderText?: string;
  notFoundText?: string;
  className?: string;
  dark?: boolean;
}

class ContentLoader extends React.Component<ContentLoaderProps> {
  static defaultProps = { hasItems: true }

  render() {
    const { notFoundHeaderText, notFoundText, retrieved, hasItems, className, dark, children } = this.props

    if (retrieved) {
      if (hasItems) {
        return children
      } else {
        return (
          <div className={`not-found ${className} ${dark ? 'dark' : ''}`}>
            {notFoundHeaderText && <h1>{notFoundHeaderText || 'Not found.'}</h1>}
            <h3 className="title">{notFoundText || 'No items found.'}</h3>
          </div>
        )
      }
    }

    return (
      <Grid  className="loading-container" justify="center" alignContent="center" container>
        <CircularProgress size={35}  />
      </Grid>
      )
  }
}

export default ContentLoader
