/* eslint-disable import/first */
import { RouteProps } from 'react-router-dom'
import { lazy } from 'react'

const routes: RouteProps[] = [
  {
    path: '/',
    component: lazy(() => import('./views/LoggedIn'))
  },
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('./views/LoggedOut/InternalLogin'))
  },
  {
    path: '/staff',
    exact: true,
    component: lazy(() => import('./views/LoggedOut/InternalLogin'))
  },
  {
    path: '/reset-password',
    exact: true,
    component: lazy(() => import('./views/LoggedOut/ResetPassword'))
  },
  {
    path: '/reset-password/:token',
    exact: true,
    component: lazy(() => import('./views/LoggedOut/ResetPassword/NewPassword'))
  }
]

export default routes
