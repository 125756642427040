import React from 'react'
import { observer } from 'mobx-react'
import { observable, makeObservable, action } from 'mobx';
import { FormHelperText } from '@material-ui/core'
import AsyncCreatableSelect from 'react-select/async-creatable';
import Async from 'react-select/async';

import _ from 'lodash'

import './style.scss'

interface SelectProps {
  name: string;
  label?: string;
  labelPlural?: string;
  value?: number | null;
  creatable?: boolean;
  options: {value: number | string, label: string}[];
  optionsSortBy?: string | Array<string>;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  loading?: boolean;
  onChange: (e: any) => void;
}

const VISIBLE_COUNT = 40

const Select = class Select extends React.Component<SelectProps> {
  text: { value: number | string; label: string; } | null | undefined;
  searchText: string = '';
  creatingNew: boolean = false

  constructor(props: SelectProps) {
    super(props);

    makeObservable(this, {
      text: observable,
      searchText: observable,
      creatingNew: observable
    });
  }

  componentWillReceiveProps(_prevProps: SelectProps) {
    if (!this.creatingNew) {
      this.text = this.props.value ? this.props.options.find(option => option.value === this.props.value) : null
    }
  }

  get label() {
    let { label, labelPlural, loading, disabled } = this.props
    labelPlural = labelPlural || `${label}${this.props.value ? '' : 's'}`

    if (loading) {
      return `Loading ${labelPlural}...`
    }

    if(!loading && !disabled && !this.filteredOptions.length){
      return `Type to create new ${label}`
    }

    if (!loading && !this.props.options.length) {
      return `No ${labelPlural} found`
    }

    return labelPlural
  }

  get filteredOptions() {
    const value = this.searchText.trim().toLowerCase()

    const sortBy = this.props.optionsSortBy
    let options = _.sortBy(this.props.options, sortBy ? sortBy : 'label')
    if (value !== '') {
      options = options.filter((suggestion: any) =>
        suggestion.label.trim().toLowerCase().indexOf(value) > -1
      )
    }

    let invisibleResults = options.length - VISIBLE_COUNT

    const moreResults = {
      value: 0,
      label: '...and ' + invisibleResults + ' more results',
      isDisabled: true
    }    

    options = options.splice(0, VISIBLE_COUNT)

    if (invisibleResults > 0) {
      options.push(moreResults)
    }
    
    return options
  }


  handleResetValue = action(() => {
    this.text = null
    this.searchText = ''
    this.props.onChange({ target: {
        name: this.props.name,
        value: null
      }
    })
  })

  handleSearchChange = (newValue: any, actionMeta: any) => {
    if(actionMeta.action === 'clear') {
      this.handleResetValue()
      return;
    }
    this.text = newValue

    if(actionMeta.action === 'create-option') {
      this.creatingNew = true
    }

    this.props.onChange({ target: { name: this.props.name, value: newValue ? newValue.value : null, isNew: actionMeta.action === 'create-option'}})
  };

  render() {
    const { error, disabled } = this.props
    const inputProps = {
      options: this.filteredOptions,
      loadOptions: (input: string, callback: any) => {
        this.searchText = input
        callback(this.filteredOptions)
      },
      defaultOptions: this.filteredOptions,
      onChange: this.handleSearchChange,
      isClearable: true,
      isLoading: !disabled && this.props.loading,
      isDisabled: disabled,
      placeholder: this.label,
      value: this.text,
      classNamePrefix: 'react-select',
      noOptionsMessage: () => this.props.options.length > 0 ? 'No options' : 'Type to add new'
    }
    return (
      <div className="react-select">
        {this.props.creatable ? <AsyncCreatableSelect {...inputProps} /> : <Async {...inputProps} />}        
        {error && <FormHelperText className="input-error">{error}</FormHelperText>}
      </div>
      )
  }
};

export default observer(Select)
