import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { 
  AppBar, 
  Toolbar, 
  Typography,
  Button,
  Hidden,
} from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'

import './style.scss'

interface Props extends RouteComponentProps {
  title: any;
  children?: any;
}

const PageHeader = (props: Props) => {
  return (
    <AppBar className="subnav">
      <Toolbar className="toolbar">
        <Button
          variant="contained"
          size="small"
          className="back-button dark-button"
          onClick={() => props.history.length > 2 ? props.history.goBack() : props.history.push('/') }
        >
          <ChevronLeft /> <Hidden xsDown>Go Back</Hidden>
        </Button>

        <Typography variant="h6" color="primary" className="title">
          {props.title}
        </Typography>

        {props.children}
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(PageHeader)
