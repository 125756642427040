import React from 'react'
import { Button, Popover, Typography } from '@material-ui/core'
import { Share } from '@material-ui/icons'
import copy from 'copy-to-clipboard'

import './style.scss'

interface Props {
  onlyIcon?: boolean;
}

const ShareButton = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  function share(e: React.MouseEvent<HTMLButtonElement>) {
    if ((navigator as any).share) {
      (navigator as any).share({
        title: document.title,
        url: window.location.href
      })
      .then()
      .catch(console.error);
    } else {
      setAnchorEl(e.currentTarget)
      copy(window.location.href)
      setTimeout(() => handleClose(), 1500)
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        size="small"
        title="Copy url to clipboard"
        className="share-button"
        onClick={share}
      >
        <Share className="icon" />{!props.onlyIcon && 'SHARE'}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className="copied-text">Copied to clipboard!</Typography>
      </Popover>
    </React.Fragment>
  )
}

export default ShareButton