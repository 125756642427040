import React from 'react'
import { observer } from 'mobx-react'
import { BrowserRouter as Router } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import _ from 'lodash'

import { RouteHandler, ScrollToTop } from './components'
import routes from './_routes'

import AccountStore from './stores/Account'
import { observable, makeObservable, action } from 'mobx';

const { LastLocationProvider } = require('react-router-last-location')

type Contrast = 'light' | 'dark';

const primaryColor = {
  50: 'rgb(14, 18, 113)',
  100: 'rgb(14, 18, 113)',
  200: 'rgb(14, 18, 113)',
  300: 'rgb(14, 18, 113)',
  400: 'rgb(14, 18, 113)',
  500: 'rgb(14, 18, 113)',
  600: 'rgb(14, 18, 113)',
  700: 'rgb(14, 18, 113)',
  800: 'rgb(14, 18, 113)',
  900: 'rgb(14, 18, 113)',
  A100: 'rgb(192, 193, 247)',
  A200: 'white',
  A400: 'white',
  A700: 'white',
  contrastDefaultColor: 'light' as Contrast
}

const primaryColorCastrol = {
  50: '#00833b',
  100: '#00833b',
  200: '#00833b',
  300: '#00833b',
  400: '#00833b',
  500: '#00833b',
  600: '#00833b',
  700: '#00833b',
  800: '#00833b',
  900: '#00833b',
  A100: 'rgb(192, 193, 247)',
  A200: 'white',
  A400: 'white',
  A700: 'white',
  contrastDefaultColor: 'light' as Contrast
}

const secondaryColorCastrol = {
  50: '#f5564a',
  100: '#f5564a',
  200: '#f5564a',
  300: '#f5564a',
  400: '#f5564a',
  500: '#f5564a',
  600: '#f5564a',
  700: '#f5564a',
  800: '#f5564a',
  900: '#f5564a',
  A100: '#f5564a',
  A200: '#f5564a',
  A400: '#f5564a',
  A700: '#f5564a',
  contrastDefaultColor: 'dark' as Contrast
}

const secondaryColor = {
  50: '#ffd700',
  100: '#ffd700',
  200: '#ffd700',
  300: '#ffd700',
  400: '#ffd700',
  500: '#ffd700',
  600: '#ffd700',
  700: '#ffd700',
  800: '#ffd700',
  900: '#ffd700',
  A100: '#ffd700',
  A200: '#ffd700',
  A400: '#ffd700',
  A700: '#ffd700',
  contrastDefaultColor: 'dark' as Contrast
}

const App = observer(class App extends React.Component {
  theme: any = null;

  constructor(props: any) {
    super(props);

    makeObservable(this, {
      theme: observable
    });
  }

  componentDidMount = action(() => {
    if (!_.includes(window.location.pathname, '/staff') && !AccountStore.isCastrol) {
      AccountStore.isSharedLogin = true
    }
    AccountStore.getProfile()
    
    this.theme = createMuiTheme({
      overrides: {
        MuiTypography: {
          subtitle1: {
            fontWeight: 'bold',
          },
        },
        // make dialogs look better on mobile
        MuiDialog: {
          paperFullWidth: {
            margin: 0,
            width: 'calc(100% - 48px)'
          }
        }
      },      
      typography: {
        fontFamily: [
          'Maven Pro',
          'Helvetica',
          'Arial',
          'sans-serif',
        ].join(',')
      },
      palette: {
        type: 'light',
        primary: AccountStore.isCastrol ? primaryColorCastrol : primaryColor,
        secondary: AccountStore.isCastrol ? secondaryColorCastrol : secondaryColor,
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 650,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },      
    })

    // Castrol modifications
    if (AccountStore.isCastrol) {
      document.body.classList.add('castrol')
      document.title = 'Castrol Labcheck'
    } else {
      document.title = 'myKomatsu - CMS'
    }
  })

  render() {
    return (
      <Router basename={!AccountStore.isCastrol ? "/cms" : '/'}>
        <LastLocationProvider>
          <ScrollToTop>
            {this.theme &&
              <ThemeProvider theme={this.theme}>
                <CssBaseline />
                <RouteHandler
                  isAuthenticated={AccountStore.meta.isAuthenticated}
                  routes={routes}
                />
              </ThemeProvider>
            }
          </ScrollToTop>
        </LastLocationProvider>
      </Router>
    )
  }
});

export default App
