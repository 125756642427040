import * as React from 'react'
import * as _ from 'lodash'

import './style.scss'

interface StatusComponentProps {
  color: string;
  title: string;
}

class StatusComponent extends React.Component<StatusComponentProps> {

  render() {
    let { color, title } = this.props

    return (
      <div className="health-indicator" style={{backgroundColor: color}}>
        <div className="inner">{title && _.upperCase(title)}</div>
      </div>
    )
  }
}

export default StatusComponent
