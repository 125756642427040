import React from 'react'
import { Route, RouteProps, Switch } from 'react-router-dom'
import { CircularProgress, Grid } from '@material-ui/core'
import { omit } from 'lodash'

export interface RouteHandlerDef {
  routes: RouteProps[];
  useSwitch?: boolean;
  [param: string]: any;
}

const Loading = () => (
  <Grid className="loading-container" justify="center" alignContent="center" container>
    <CircularProgress size={35} />
    {/* Loading... */}
  </Grid>
)

const RouteHandler = (props: RouteHandlerDef & React.ClassAttributes<any>) => {
  const Routes = props.routes.map((route, i) => {
    const parentProps = omit(props, ['children']);

    return (
      <Route
        key={i}
        exact={route.exact}
        strict={route.strict}
        path={route.path}
        render={(componentProps) => (
          route.component ? <route.component {...componentProps} {...parentProps} /> : undefined
        )}
      />
    )
  })

  return (
    <React.Suspense fallback={<Loading />}>
      {props.useSwitch
        ? <Switch>{Routes}</Switch>
        : Routes
      }
    </React.Suspense>
  )
}

export default RouteHandler
