import React from 'react'

import './style.scss'

interface Props {
  className?: string;
  children: React.ReactNode;
}

const Content = (props: Props) =>
  <div className={`content ${props.className}`}>
    {props.children}
  </div>

export default Content
