import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react'
import ReactDOM from 'react-dom'

import './styles/App.scss'
import App from './app/App'
import * as serviceWorker from './serviceWorker'

import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
