import { observable, action, makeObservable } from 'mobx';
import queryString from 'query-string'
import _ from 'lodash'

import API from '../API'
import { Report, Linkage,  Meta, CreateLinkage, EmailRecipient } from './index.d'
import downloadFile from 'app/utils/downloadFile'

class ReportStore {
  reports: Report[] = [];
  linkages: Linkage[] = [];
  meta: Meta = {
    retrieved: false,
    linkagesRetrieved: false,
  };

  constructor() {
    makeObservable(this, {
      reports: observable,
      linkages: observable,
      meta: observable,
      getReports: action,
      reset: action,
      getLinkages: action,
      updateLinkage: action,
      deleteLinkage: action
    });
  }

  getReports() {
    this.meta.retrieved = false

    return API.get('/reports')
      .then(action(async (res) => {
        this.reports = res.data
      }))
      .finally(action(() => {
        this.meta.retrieved = true
      }))
  }

  emailReports(options: { recipients: EmailRecipient[], sampleNumbers: number[], comment: string }) {
    return API.post(`/reports/email`, options)
  }

  showReport(name: string, settings: Object) {
    downloadFile(`${process.env.REACT_APP_API_URL}/reports/${name}?${queryString.stringify(settings)}`)
  }

  reset() {
    this.reports = []
    this.meta = { retrieved: false, linkagesRetrieved: false }
  }

  getLinkages() {
    this.linkages = []
    this.meta.linkagesRetrieved = false

    return API.get('/reports/linkages', { params: {} })
      .then(action(async (res) => {
        this.meta.linkagesRetrieved = true
        this.linkages = res.data
      }))
  }

  updateLinkage(linkage: CreateLinkage) {
    return API.put('/reports/linkages', _.mapValues(linkage, (v) => v === 0 ? null : v))
      .then(action(async (res) => {
        this.linkages = res.data
      }))
  }

  deleteLinkage(linkageId: number) {
    return API.delete(`/reports/linkages/${linkageId}`)
      .then(action(() => {
        this.linkages = this.linkages.filter(linkage => linkage.contactLinkageId !== linkageId)
      }))
  }
}

export default new ReportStore()
